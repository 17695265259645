<template>
  <div class="container center" style="margin-top: 50px">

    <div class="form-group" style="font-size: larger">
      {{
        this.$route.query.language === 'en' ?
            'Thank you for letting us know, our team is working hard to help you better. We may reach out to you to follow up or with an update on how we have improved based on your feedback. Thank you again, The ' + this.$route.query.storefront + 'Team'
            : "Merci de nous l'avoir fait savoir, notre équipe travaille durement pour mieux vous aider. Nous pourrions vous contacter pour faire un suivi ou pour vous informer de la façon dont nous nous sommes améliorés en fonction de vos commentaires. Merci encore, L'équipe " + this.$route.query.storefront
      }}

    </div>

    <b-row style="margin: 0">
      <b-button class="nextBtn" @click="goBack">{{
          this.$route.query.language === 'en' ? 'Go Back' : 'Retour en arrière'
        }}
      </b-button>
    </b-row>

  </div>
</template>

<script>

export default {
  methods: {
    goBack() {
      this.$router.go(-2);
    }
  }
}

</script>
